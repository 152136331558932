

















import { Component, Prop, Vue } from "vue-property-decorator";
import ContractDetailPage from "@/views/Contract/Detail/index.vue";

@Component({ components: { ContractDetailPage } })
export default class ContractDetail extends Vue {
  @Prop({ default: "modal-contract-detail" }) private id!: string;
  @Prop() private contractId!: string;
  @Prop() private billId?: string;

  private background: any = {
    backgroundImage: "url(" + require("@/assets/images/main_bg.jpg") + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  };
}
