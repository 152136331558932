









import { Component, Vue } from "vue-property-decorator";
import BlockUI from "@/components/BlockUI.vue";
import CheckDevice from "./helpers/CheckDevice";

@Component({ components: { BlockUI } })
export default class App extends Vue {
  created() {}

  updated() {
    let appHeight = (this.$refs.app as any).clientHeight;
    let windowHeight = window.innerHeight;
    if (appHeight > windowHeight && !CheckDevice.isMobile()) {
      document.body.classList.add("body-scroll-pc");
    } else {
      document.body.classList.remove("body-scroll-pc");
    }
  }
}
