import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import { CommonData } from '@/models'

class CommonService {

  getAllCompany() {
    return axiosApiInstance.get(Const.api_url + '/company')
  }

  getAllSupplier() {
    return axiosApiInstance.get(Const.api_url + '/supplier')
  }

  getAllCountry() {
    return axiosApiInstance.get(Const.api_url + '/country')
  }

  getAllProuct() {
    return axiosApiInstance.get(Const.api_url + '/product')
  }

  getAllCarrier() {
    return axiosApiInstance.get(Const.api_url + '/carrier')
  }

  getAllPort() {
    return axiosApiInstance.get(Const.api_url + '/port')
  }

  addCompany(data: CommonData) {
    return axiosApiInstance.post(Const.api_url + '/company/save', data)
  }

  addSupplier(data: CommonData) {
    return axiosApiInstance.post(Const.api_url + '/supplier/save', data)
  }

  addCountry(data: CommonData) {
    return axiosApiInstance.post(Const.api_url + '/country/save', data)
  }

  addProuct(data: CommonData) {
    return axiosApiInstance.post(Const.api_url + '/product/save', data)
  }

  addCarrier(data: CommonData) {
    return axiosApiInstance.post(Const.api_url + '/carrier/save', data)
  }

  addPort(data: CommonData) {
    return axiosApiInstance.post(Const.api_url + '/port/save', data)
  }
}

export default new CommonService
