























































































import { SearchResult } from "@/models";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ModalSearchResult extends Vue {
  @Prop({ default: "modal-search-result" }) private id!: string;
  @Prop() private results!: SearchResult[];
  private contractId: string = "";

  formatDate(date: string) {
    const mm = this.$moment(date, "DD-MM-YYYY HH:mm:ss");
    return mm.isValid() ? mm.format("DD MMM YY").toUpperCase() : "";
  }

  jumpToDetail(rs: SearchResult) {
    // this.$router.push({
    //   name: "contract-detail",
    //   params: { contractId: rs.contractId }
    // });
    // this.$bvModal.hide(this.id);
    this.contractId = rs.contractId;
    this.$bvModal.show("modal-contract-detail-from-search");
  }
}
