import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import { UserInfo } from '@/models'

class UserService {

  getAll() {
    return axiosApiInstance.get(Const.api_url + '/user')
  }

  getNote() {
    return axiosApiInstance.get(Const.api_url + '/user/note')
  }

  saveNote(note: string) {
    return axiosApiInstance.put(Const.api_url + '/user/note/save', { notice: note })
  }

  updateUser(user: UserInfo) {
    return axiosApiInstance.post(Const.api_url + '/user/save', user)
  }

  changePasswordAdmin(username: string, password: string) {
    return axiosApiInstance.put(Const.api_url + '/user/change-password-admin', { username: username, passwordNew: password })
  }

  changePassword(username: string, passwordOld: string, passwordNew: string) {
    return axiosApiInstance.put(Const.api_url + '/user/change-password', { username: username, passwordOld: passwordOld, passwordNew: passwordNew })
  }
}

export default new UserService
