import Vue from 'vue'
import VueI18n from 'vue-i18n'

// @ts-ignore
import ValidationViLocale from '@/validations/lang/vi.ts'
// @ts-ignore
import ValidationEnLocale from '@/validations/lang/en.ts'
// User defined lang
import viLocale from './vi'
import enLocale from './en'

Vue.use(VueI18n)

const messages = {
  vi: {
    ...viLocale,
    ...{
      fields: ValidationViLocale.fields,
      validation: ValidationViLocale.messages
    }
  },
  en: {
    ...enLocale,
    ...{
      fields: ValidationEnLocale.fields,
      validation: ValidationEnLocale.messages
    }
  }
}

export const getLocale = () => {
  const language = 'vi' //TODO - get from store
  return language ? language : 'vi'
}

const i18n = new VueI18n({
  locale: getLocale(),
  messages
})

export default i18n
