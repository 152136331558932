export default {
  code: "en",
  fields: {
    current_password: "Current password",
    new_password: "Password",
    password: "Password",
    confirmPassword: "Password Verification",
    title: "title",
    message: "Message",
    postal_code: "Group Address",
    phoneNumberGroup: "Group Phone Number",
    user_id: "User ID",
    email: "Email address",
    newEmail: "New Email Address",
    companyId: "Company ID",
    employeeId: "Employee ID",
    phone_number: "Phone Number",
    postCode: "Address",
    mail_title: "Email Subject",
    mail_content: "Body",
    equipment_name: "Facility Name",
    equipment_memo: "Equipment Memo",
    auto_clear_bin: "Trash Auto Delete",
    upload_date: "Delivery Date and Time",
    selected_group_tag: "Select To",
    folder_name: "Folder Name",
    employee_code: "Employee Number",
    first_name: "Last Name",
    last_name: "First Name",
    first_name_kana: "Sei",
    last_name_kana: "Mei",
    self_introduction: "Self-Introduction",
    company: "Company/Affiliation",
    department: "Department",
    position: "Job title",
    prefecture: "State",
    district: "City",
    other_address: "Other Addresses",
    building_name: "Building Name",
    startDateEvent: "Start date and time",

    endDateEvent: "End Date and Time",

    keyword_search: "Keyword search",
    regular_stop_notify_start: "Email notification stop time zone",
    regular_stop_notify_end: "Email notification stop time zone",
    emergency_stop_notify_start: "Email notification stop time zone",
    emergency_stop_notify_end: "Email notification stop time zone",
    like_character: "Like Letters",
    comment: "Comment",
    group_notice: "Notice to members",
    target_search: "Searched"
  },
  messages: {
    email: "Please enter your e-mail address correctly.",
    length: "The {_field_} field must be {length} long",
    max_value: "The {_field_} field must be {max} or less",
    min_value: "The {_field_} field must be {min} or more",
    min_date: "{_field_} must be at least {date_format}",
    max_date: "{_field_} must be less than or equal to {date_format}",
    min: "The {_field_} field must be at least {length} characters",
    max: "The {_field_} field may not be greater than {length} characters",
    numeric: "The {_field_} field may only contain numeric characters",
    regex: "The {_field_} field format is invalid",
    required: "Please enter your {_field_}",
    size: "The {_field_} field size must be less than {size}KB",
    input: {
      required: "is required",
      exceedsPermissibleLength: "more than allowable length",
      formatError: "format error",
      lessThanPermissibleLength: "less than allowable length"
    },
    check_password: "Password is invalid",
    postal_code: "Zip code must be 7 digits excluding dashes",
    phone_number: "Phone numbers can contain up to 13 numbers, including dashes",
    url: "URL is not a valid URL",
    duplicate_orignal_email: "The email address you entered and the original email address cannot be the same",
    check_email: "The second email address and the third email address cannot be the same",
    check_kana: "Enter {_field_}as hiragana",
    text_number_only: "{_field_} should not be able to use emojis and symbols",
    text_number_only_no_space: "{_field_} should not be able to use emojis, symbols, and spaces",
    no_emoji: "{_field_} should not use emojis",
    compare_start_end_time: "Start time can not be the same as end time.",
    between: "The {_field_} field must be between {min} and {max}",
    invalid: "format error",
    business: {
      emailNotRegistered: "Email not registerd",
      userPasswordNotMatch: "User password not match",
      userNotFound: "User not found",
      sendMailSuccess: "We have sent an e-mail with the password reset URL to the specified e-mail address. please confirm.",
      invalidRegistrationTime: "Reservations cannot be made retroactively",
      seatIsAlreadyRegistered: "The seat has already been reserved for another user. Click the display button again to refresh the screen.",
      outsideWorkingHours: "It is outside business hours"
    },
    departmentVsGroupInvalid: "There is no link between department and section. please confirm.",
    phoneNumberGroup: "{_field_} is invalid.",
    minTime: "Closing time must be greater than opening time.",
    maxTime: "The Closing time must be less than 23:59",
    no_script_tag: "{_field_} chứa những ký tự không hợp lệ"
  }
}
