








































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import PreviewText, { IPreviewText } from "@/helpers/PreviewText";
import ModalChangePassword from "@/components/Modal/ModalChangePassword.vue";
import ModalSearchResult from "@/components/Modal/ModalSearchResult.vue";
import TextEditor from "@/views/TextEditor/index.vue";
import ContractService from "@/services/ContractService";
import { SearchResult } from "@/models";
import AuthService from "@/services/AuthService";

@Component({
  components: { ModalSearchResult, TextEditor, ModalChangePassword }
})
export default class Header extends Vue {
  @Prop() private sideBarCollapse!: boolean;
  private noAvatar = require("@/assets/images/avatar-default.png");
  private logo: string = require("@/assets/images/lucas-logos/lucas-logos_transparent_no_text.png");
  private fullname!: IPreviewText;
  private keyWord: string = "";
  private results: SearchResult[] = [];
  private popoverShow: boolean = false;

  created() {
    this.$store.state.user.fullname;
    this.fullname = PreviewText.covertToPreviewText(
      this.$store.state.user.fullname,
      20
    );
  }

  onToggleCollapse() {
    this.$emit("toggleCollapse", !this.sideBarCollapse);
  }

  logout() {
    const token = this.$store.state.auth.token;
    token ? AuthService.logout(token) : "";
    localStorage.clear();
    this.$router.push({ name: "login" });
  }

  jumpToDashboard() {
    this.$router.push({ name: "dashboard" });
  }

  onShow() {
    this.keyWord = "";
  }

  onShown() {
    this.$nextTick(() => {
      this.$nextTick(() => {
        (
          (this.$refs.searchPopover as any).$el || this.$refs.searchPopover
        ).focus();
      });
    });
  }

  searchContract() {
    if (!this.keyWord.trim().length) return;
    ContractService.findByContractCode(this.keyWord.trim())
      .then(res => {
        if (res.status === 200) {
          if (!res.data.length) {
            this.$swal.fire({
              icon: "warning",
              title: "Không tìm thấy!",
              timer: 3000
            });
          } else {
            this.results = res.data;
            setTimeout(() => this.$bvModal.show("modal-search-result"), 10);
          }
        }
      })
      .catch(() => {
        this.$swal.fire({
          icon: "warning",
          title: "Không tìm thấy!",
          timer: 3000
        });
      })
      .finally(() => {
        this.keyWord = "";
        this.popoverShow = false;
      });
  }
}
