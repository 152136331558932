import moment from "moment";
import { Bill } from "..";
export class ContractViewModel {
  id!: number;
  code: string = "";
  company: string = "";
  timeCreate: string = "";
  timeSigned: string = "";
  license: string = "";
  paymentMethod: string = "";
  // totalValue: string = "";
  // totalValueTemp: string = "";
  // totalPrice: string = "";
  // totalPriceTemp: string = "";
  valueList: string = "";
  valueTempList: string = "";
  // priceList: string = "";
  // priceTempList: string = "";
  bills: string = "";
  invoices: string = "";
  products: string = "";
  productsFinal: string = "";
  supplierCode: string = "";
  supplier: string = "";
  origin: string = "";
  notice: string = "";
  color: string = "";
  status: ContractStatus = ContractStatus.NEW;

  //view model
  productsVM: string[] = [];
  productsFinalVM: string[] = [];
  valueListVM: string[] = [];
  valueTempListVM: string[] = [];
  // priceListVM: string[] = [];
  // priceTempListVM: string[] = [];
  statusVM?: ContractStatus;
  originalIndex!: number;
  constructor(init?: Partial<ContractViewModel>) {
    Object.assign(this, init);
    this.statusVM = (this.status);

    this.valueList?.endsWith("\n")
      ? (this.valueList = this.valueList.slice(0, this.valueList.length - 1))
      : "";
    this.valueTempList?.endsWith("\n")
      ? (this.valueTempList = this.valueTempList.slice(
        0,
        this.valueTempList.length - 1
      ))
      : "";
    // this.priceList?.endsWith("\n")
    //   ? (this.priceList = this.priceList.slice(0, this.priceList.length - 1))
    //   : "";
    // this.priceTempList?.endsWith("\n")
    //   ? (this.priceTempList = this.priceTempList.slice(
    //     0,
    //     this.priceTempList.length - 1
    //   ))
    //   : "";

    this.productsVM = this.products?.split("\n");
    this.productsFinalVM = this.productsFinal?.split("\n");
    this.valueListVM = this.valueList?.split("\n");
    this.valueTempListVM = this.valueTempList?.split("\n");
    // this.priceListVM = this.priceList?.split("\n");
    // this.priceTempListVM = this.priceTempList?.split("\n");
    // this.totalValue = new Intl.NumberFormat("currency").format(Number(this.totalValue))
    // this.totalValueTemp = new Intl.NumberFormat("currency").format(Number(this.totalValueTemp))
    // this.totalPrice = new Intl.NumberFormat("currency").format(Number(this.totalPrice))
    // this.totalPriceTemp = new Intl.NumberFormat("currency").format(Number(this.totalPriceTemp))
    this.timeCreate = this.timeCreate
      ? moment(this.timeCreate, "DD-MM-YYYY")
        .format("DD MMM YY")
        .toUpperCase()
      : "";
    this.timeSigned = this.timeSigned
      ? moment(this.timeSigned, "DD-MM-YYYY")
        .format("DD MMM YY")
        .toUpperCase()
      : "";
  }
}

export class NewContract {
  id!: number;
  status: ContractStatus = ContractStatus.NEW;
  code: string = "";
  timeSigned!: Date;
  company: string = "";
  color: string = "";
  creator: string = "";
  brand: string = "";
  origin: string = "";
  supplier: string = "";
  supplierCode: string = "";
  port: string = "";
  portName: string = "";
  paymentMethod: string = "";
  license: string = "OFFLINE";
  exchangeRate: string = "";
  notice: string = "";
  contractProductList: Product[] = [];
  billList: Bill[] = [];
  totalValueTemp: number = 0;
  // totalPriceTemp: number = 0;
  totalValue: number = 0;
  // totalPrice: number = 0;

  //view model
  licenseVM: boolean = false;

  constructor(init?: Partial<NewContract>) {
    Object.assign(this, init);
    this.timeSigned?.setHours(0, 0, 0);
    this.licenseVM = this.license === "ONLINE";
  }
}

export class Contract {
  id!: number;
  code: string = "";
  status: ContractStatus = ContractStatus.NEW;
  timeSigned: Date = new Date();
  company: string = "";
  origin: string = "";
  supplier: string = "";
  supplierCode: string = "";
  brand: string = "";
  totalValueTemp: string = "";
  // totalPriceTemp: string = "";
  totalValue: string = "";
  // totalPrice: string = "";
  paymentMethod = "";
  license: string = "OFFLINE";
  exchangeRate: string = "";
  notice: string = "";
  billList: Bill[] = [];
  contractProductList: Product[] = [];

  constructor(init?: Partial<Contract>) {
    Object.assign(this, init);
  }
}

export class ContractCSV {
  company: string = "";
  supplier: string = "";
  contractCode: string = "";
  brand: string = "";
  timeSigned: string = "";
  license: string = "";
  paymentMethod: string = "";
  exchangeRate: string = "";
  origin: string = "";
  billNo: string = "";
  invoiceNo: string = "";
  shipCode: string = "";
  carrier: string = "";
  billDate: string = "";
  dpName: string = "";
  apName: string = "";
  fpName: string = "";
  etd: string = "";
  eta: string = "";
  etf: string = "";
  vetDate: string = "";
  clearDate: string = "";
  containerCode: string = "";
  productName: string = "";
  value: string = "";
  // price: string = "";
  weight: string = "";
  packs: string = "";
  constructor(init?: Partial<ContractCSV>) {
    Object.assign(this, init);

    const toString = (str: string) => {
      const mm = moment(str, "DD-MM-YYYY HH:mm:ss");
      if (mm.isValid()) {
        return mm.format("DD MMM YY").toUpperCase();
      }
      return "";
    };

    this.timeSigned = toString(this.timeSigned);
    this.billDate = toString(this.billDate);
    this.etd = toString(this.etd);
    this.eta = toString(this.eta);
    this.etf = toString(this.etf);
    this.vetDate = toString(this.vetDate);
    this.clearDate = toString(this.clearDate);
  }
}

export class Product {
  contractId: any = null;
  productId!: number;
  productName: string = "";
  value!: number;
  // price!: number;
  weight!: number;
  packs!: number;
  constructor(init?: Partial<Product>) {
    Object.assign(this, init);
  }
}

export class PNote {
  id!: number;
  contractId!: number;
  pnote: string = "";
  constructor(init?: Partial<PNote>) {
    Object.assign(this, init);
  }
}

export enum PaymentMethod {
  TT,
  DP,
  LC
}

export enum ContractStatus {
  DELETED,
  NEW,
  PROCESSED,
}
