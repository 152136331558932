import moment from 'moment'
import { Product, CommonData, ContractStatus, IdName } from '..'

export class BillViewModel {
  id!: number
  contractId!: number
  contractCode: string = ''
  contractStatus: string = ''
  company: string = ''
  color: string = ''
  billNo: string = ''
  invoiceNo: string = ''
  billDate: string = ''
  carrier: string = ''
  carrierName: string = ''
  shipCode: string = ''
  dp: string = ''
  dpname: string = ''
  etd: string = ''
  ap: string = ''
  apname: string = ''
  eta: string = ''
  fp: string = ''
  fpname: string = ''
  etf: string = ''
  products: string = ''
  // prices: string = ''
  values: string = ''
  // sumPrice: number = 0
  sumValue: number = 0
  vetCheck: boolean = false
  clearCheck: boolean = false
  vetDate: string = ''
  clearDate: string = ''
  notice: string = ''
  containers: Container[] = []

  //viewmodal
  originalIndex!: number
  productsVM: string[] = [];
  // pricesVM: string[] = [];
  valuesVM: string[] = [];
  constructor(init?: Partial<BillViewModel> | Partial<Bill>) {
    Object.assign(this, init);

    // this.prices?.endsWith('\n') ? this.prices = this.prices.slice(0, this.prices.length - 1) : ''
    this.values?.endsWith('\n') ? this.values = this.values.slice(0, this.values.length - 1) : ''
    this.productsVM = this.products?.split('\n')
    // this.pricesVM = this.prices?.split('\n')
    this.valuesVM = this.values?.split('\n')

    const toString = (str: string) => {
      const mm = moment(str, "DD-MM-YYYY HH:mm:ss");
      if (mm.isValid()) {
        return mm.format("DD MMM YY").toUpperCase();
      }
      return "";
    };

    this.billDate = toString(this.billDate);
    this.etd = toString(this.etd);
    this.eta = toString(this.eta);
    this.etf = toString(this.etf);
    this.vetDate = toString(this.vetDate);
    this.clearDate = toString(this.clearDate);

  }
}

export class BillCSV {
  contractCode: string = ''
  company: string = ''
  billNo: string = ''
  invoiceNo: string = ''
  billDate: string = ''
  carrier: string = ''
  shipCode: string = ''
  DP: string = ''
  ETD: string = ''
  AP: string = ''
  ETA: string = ''
  FP: string = ''
  ETF: string = ''
  vetCheck: string = ''
  clearCheck: string = ''
  vetDate: string = ''
  clearDate: string = ''
  notice: string = ''

  constructor(init?: Partial<BillViewModel> | Partial<Bill>) {
    this.contractCode = init?.contractCode as string
    this.company = init?.contractCode as string
    this.billNo = init?.billNo as string
    this.invoiceNo = init?.invoiceNo as string
    this.billDate = init?.billDate as string
    this.carrier = `${init?.carrierName} (${init?.carrier})`
    this.shipCode = init?.shipCode as string

    this.DP = `${init?.dpname} (${init?.dp})`
    this.AP = `${init?.apname} (${init?.ap})`
    this.FP = `${init?.fpname} (${init?.fp})`

    const toString = (str: any) => {
      const mm = moment(str, "DD-MM-YYYY HH:mm:ss");
      if (mm.isValid()) {
        return mm.format("DD MMM YY").toUpperCase();
      }
      return "";
    };

    this.billDate = toString(this.billDate);
    this.ETD = toString(init?.etd);
    this.ETA = toString(init?.eta);
    this.ETF = toString(init?.etf);
    this.vetDate = toString(init?.vetDate);
    this.clearDate = toString(init?.clearDate);

    this.vetCheck = init?.vetCheck ? 'Đã kiểm dịch' : 'Chưa kiểm dịch'
    this.clearCheck = init?.clearCheck ? 'Đã thông quan' : 'Chưa thông quan'

    this.notice = init?.notice as string
  }
}

export class Bill {
  id!: number | string
  contractId!: number
  contractCode: string = ''
  contractStatus: ContractStatus = ContractStatus.NEW
  billStatus: BillStatus = BillStatus.PROCESSING
  billNo: string = ''
  invoiceNo: string = ''
  carrier: string = ''
  carrierName: string = ''
  shipCode: string = ''
  billDate: Date | null = null
  dp: string = ''
  dpname: string = ''
  etd: Date | null = null
  ap: string = ''
  apname: string = ''
  eta: Date | null = null
  fp: string = ''
  fpname: string = ''
  etf: Date | null = null
  vetCheck: boolean = false
  clearCheck: boolean = false
  vetDate: Date | null = null
  clearDate: Date | null = null
  notice: string = ''
  containers: Container[] = []

  //view model
  invalid: boolean = false

  constructor(init?: Partial<Bill> | Partial<BillViewModel>) {
    Object.assign(this, init);
  }
}

export class Container {
  id!: number;
  billId!: number | string;
  code: string = "Container";
  containerProducts: Product[] = []

  //viewmodel
  invalid: boolean = false;
  constructor(init?: Partial<Container>) {
    Object.assign(this, init);
  }
}

export class Calendar {
  contractId!: number;
  billId!: number;
  contractCode: string = '';
  time: string = '';
  color: string = '';
}

export class BillFilter {
  etaFrom: Date | undefined = undefined;
  etaTo: Date | undefined = undefined;
  signedDateFrom: Date | undefined = undefined;
  signedDateTo: Date | undefined = undefined;
  clearFrom: Date | undefined = undefined;
  clearTo: Date | undefined = undefined;
  text: string = '';
  countryId: number | undefined = undefined
  companyId: number | undefined = undefined
  etaPort: number | undefined = undefined
  productIds: number[] = []
  statuses: BillStatus[] = []

  //VM
  process: boolean = true;
  done: boolean = false;
  origin: CommonData = new CommonData()
  company: CommonData = new CommonData()
  port: CommonData = new CommonData()
  selectedProds: FilterProductTag[] = [];

  constructor(init?: Partial<BillFilter>) {
    Object.assign(this, init)
    this.countryId = this.origin?.id
    this.companyId = this.company?.id
    this.etaPort = this.port?.id
    this.statuses = []
    this.process ? this.statuses.push(BillStatus.PROCESSING) : ''
    this.done ? this.statuses.push(BillStatus.DONE) : ''
  }
}

export class FilterProductTag {
  text: string = "";
  value: IdName = new IdName();
  constructor(init?: Partial<CommonData>) {
    Object.assign(this.value, new IdName(init));
    this.text = this.value.name;
  }
}
export class VetAndClear {
  billId!: number;
  status: boolean = false;
  type!: CheckType;
  date: Date | null | string = null;

  constructor(init?: Partial<VetAndClear>) {
    Object.assign(this, init);
  }
}

export enum CheckType {
  VET,
  CLEAR
}

export enum BillStatus {
  PROCESSING,
  DONE,
}

