import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import BlockUi from '@/store/modules/BlockUi';
import Auth from '@/store/modules/Auth';
import User from '@/store/modules/User';
import Todo from './modules/Todo'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    blockUi: BlockUi,
    auth: Auth,
    user: User,
    todo: Todo
  },
  plugins: [createPersistedState({ paths: ['todo', 'auth', 'user'] })]
})

export default store
