import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from './store'
import './assets/scss/index.scss'
import i18n from '@/lang'
import './validations/validation'
import moment from 'moment'
// @ts-ignore
import lodash from 'lodash'
import { BootstrapVue } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VueRx from 'vue-rx'
// @ts-ignore
import DatePicker from 'vue2-datepicker';
import VueSweetalert2 from 'vue-sweetalert2';
// @ts-ignore
import JsonCSV from 'vue-json-csv'


import ModalAddCommon from '@/components/Modal/ModalAddCommon.vue'
import ModalContractDetail from '@/views/Contract/Detail/ModalContractDetail.vue'

import BlockUi from '@/store/modules/BlockUi'
import { getModule } from 'vuex-module-decorators'
import { Component } from 'vue-property-decorator'

const BlockUiModule = getModule(BlockUi, store)

moment.locale('en');

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ModalAddCommon', ModalAddCommon)
Vue.component('ModalContractDetail', ModalContractDetail)
Vue.component('DatePicker', DatePicker)
Vue.component('downloadCsv', JsonCSV)

Vue.use(BootstrapVue)
Vue.use(VueRx)
Vue.use(VueSweetalert2);

const EventBus = new Vue()

Vue.prototype.$bus = EventBus
Vue.prototype.$moment = moment
Vue.prototype.$lodash = lodash
Vue.prototype.$blockui = {
  show: () => {
    BlockUiModule.SET_ACTIVE(true)
  },
  hide: () => {
    BlockUiModule.SET_ACTIVE(false)
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $blockui: any,
    $moment: any,
    $lodash: any,
    $bus: any,
  }
}

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
