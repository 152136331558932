export default {
  lang: {
    vi: "Tiếng Việt",
    en: "Tiếng Anh",
    other: "Khác",
    choose_your_language: "Hãy chọn ngôn ngữ của bạn",
  },
  route_title: {
    login: "Đăng nhập",
    dashboard: 'Theo dõi',
    contract: 'Hợp đồng',
    custom: 'Tùy chỉnh',
    user: 'Người dùng',
    product: 'Sản phẩm',
    setting: 'Cài đặt',
    todo: 'Todo'
  },
  sidebar_title: {
    tracking: 'Theo dõi',
    contract: 'Hợp đồng',
    custom: 'Tùy chỉnh',
    user: 'Người dùng',
    product: 'Sản phẩm',
    setting: 'Cài đặt'
  },
  common: {
    date: {
      next_month: "next month",
      prev_month: "last month",
      set_time: "time",
      mon: "T2",
      tue: "T3",
      wed: "T4",
      thu: "T5",
      fri: "T6",
      sat: "T7",
      sun: "CN",
      day: "day",
      january: "Tháng 1",
      february: "Tháng 2",
      march: "Tháng 3",
      april: "Tháng 4",
      may: "Tháng 5",
      june: "Tháng 6",
      july: "Tháng 7",
      august: "Tháng 8",
      september: "Tháng 9",
      october: "Tháng 10",
      november: "Tháng 11",
      december: "Tháng 12"
    }
    ,
    table: {
      prev: 'Trước',
      next: 'Sau'
    }
  }
}