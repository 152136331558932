import Const from '../constants'
import axiosApiInstance from '@/interceptors'

class AuthService {

  login(username: string, password: string) {
    return axiosApiInstance.post(Const.api_url + '/auth', { username: username, password: password })
  }

  logout(token: string) {
    return axiosApiInstance.post(Const.api_url + '/logout', token)
  }
}

export default new AuthService
