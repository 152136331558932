


























import { Component, Vue } from "vue-property-decorator";
import Editor from "@tinymce/tinymce-vue";
import UserService from "@/services/UserService";

@Component({ components: { Editor } })
export default class TextEditor extends Vue {
  //config tiny editor
  private getTinyConfig: any = {};
  private getKey: string = "";
  private editorData: string = "";

  created() {
    this.init();
  }

  /**
   * init for TinyMCE
   */
  init() {
    this.getKey = process.env.VUE_APP_TINY_MCE_API_KEY as string;
    this.getTinyConfig = {
      min_height: 600,
      selector: "textarea#open-source-plugins",
      toolbar_mode: "wrap",
      menubar: "edit insert format table",
      entity_encoding: "raw",
      plugins: ["advlist emoticons link lists quickbars table"],
      toolbar: [
        "fontsizeselect | fontselect | formatselect | undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | emoticons link table"
      ],
      quickbars_insert_toolbar: false,
      quickbars_selection_toolbar:
        "bold italic | forecolor backcolor | quicklink"
    };
  }

  modalShow() {
    UserService.getNote().then(res => {
      if (res.status === 200) {
        this.editorData = res.data;
      }
    });
  }

  modalHide() {
    UserService.saveNote(this.editorData).then(res => {
      if (res.status === 200) {
        this.$swal.fire({
          icon: "success",
          title: "Đã lưu",
          position: "bottom-end",
          toast: true,
          showConfirmButton: false,
          timer: 2000
        });
      }
    });
  }
}
