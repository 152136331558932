
























import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/Sidebar.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

@Component({
  components: { Sidebar, Header, Footer }
})
export default class LayoutDefault extends Vue {
  private sideBarCollapse: boolean = true;
  private background: any = {
    backgroundImage: "url(" + require("@/assets/images/main_bg.jpg") + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  };

  created() {
    this.trackingWindowResize();
  }

  trackingWindowResize() {
    // const vm = this;
    // window.onresize = () => {
    //   vm.sideBarCollapse = document.body.clientWidth < 1200;
    // };
  }

  toggleCollapse(bool: boolean) {
    // this.sideBarCollapse = bool;
  }
}
