




























import { UserRole } from "@/models";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
  private menus: any[] = [
    {
      href: "/",
      title: "Theo dõi",
      icon: "fas fa-tachometer-alt"
    },
    {
      href: "/calendar",
      title: "Lịch",
      icon: "fas fa-calendar-alt"
    },
    {
      href: "/contracts",
      title: this.$t("sidebar_title.contract"),
      icon: "fas fa-file-contract",
      hiddenOnCollapse: this.$store.state.user.role === UserRole.ACCOUNTANT
    },
    {
      href: "/upload-folder",
      title: "Tài liệu",
      icon: "fas fa-folder"
    },
    // {
    //   href: "/job",
    //   title: "Giao việc",
    //   icon: "fas fa-bolt"
    // },
    {
      href: "/config",
      title: this.$t("sidebar_title.custom"),
      icon: "fas fa-cogs"
    }
  ];
}
