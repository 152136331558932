class UploadFileType {
  PDF = 'PDF'
  DOC = 'DOC'
  IMG = 'IMG'
  ZIP = 'ZIP'
  XLS = 'XLS'
  OTHER = 'OTHER'

  getIconClassName(type: String) {
    switch (type) {
      case this.PDF:
        return 'fal fa-file-pdf'
      case this.DOC:
        return 'fal fa-file-alt'
      case this.IMG:
        return 'fal fa-file-image'
      case this.ZIP:
        return 'fal fa-file-archive'
      case this.XLS:
        return 'fal fa-file-excel'
      default:
        return 'fal fa-file'
    }
  }

}

export default new UploadFileType()