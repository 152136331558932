







































































































































































import { FileFolder, UploadFile, UserRole } from "@/models";
import CommonService from "@/services/CommonService";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UploadFileType from "@/helpers/UploadFileType";
import FileService from "@/services/FileService";

@Component
export default class ModalDocument extends Vue {
  @Prop({ default: "modal-document" }) private id!: string;
  @Prop() private contractId!: number;
  @Prop() private billId!: number;
  @Prop() private disableEdit?: boolean;
  private editNotice: string = "Modal thêm file";
  private isEdit: boolean = false;
  private blockAdd: boolean = false;
  private selectIndex: number = 0;
  private fileChoose!: File;
  private tabIndex: number = 0;
  private tabs: ListTabDocument[] = [];
  private moveFolders: ListTabDocument[] = [];
  private eFileFolder = FileFolder;

  //role
  private userRole: UserRole = this.$store.state.user.role;
  private eUserRole = UserRole;

  getListDocument() {
    this.initTabs();
    if (this.billId === -1 || !this.billId) {
      FileService.getFileByContractId(this.contractId).then(res => {
        this.tabs[0].list = res.data;
      });
    } else {
      FileService.getFileByBillId(this.billId).then(res => {
        this.tabs.forEach(tab => {
          tab.list = res.data.fileList[String(tab.type)];
        });
      });
    }
  }

  @Watch("tabIndex")
  getMoveFolders() {
    this.moveFolders = this.tabs.filter((_, index) => index !== this.tabIndex);
  }

  initTabs() {
    this.tabs =
      this.billId !== -1 && this.billId
        ? [
            {
              title: "1. INV+PL",
              type: FileFolder.INV_PL,
              list: []
            },
            {
              title: "2. HC/CO/BL/INS",
              type: FileFolder.HC_CO_BL_INS,
              list: []
            },
            {
              title: "3. Tờ khai",
              type: FileFolder.DECLARE,
              list: []
            },
            {
              title: "4. 15B",
              type: FileFolder.B15,
              list: []
            },
            {
              title: "5. Ngân hàng",
              type: FileFolder.BANK,
              list: []
            },
            {
              title: "6. Khách",
              type: FileFolder.GUEST,
              list: []
            },
            {
              title: "7. Tài liệu chung",
              type: FileFolder.COMMON,
              list: []
            }
          ]
        : [
            {
              title: "Tài liệu chung",
              type: FileFolder.COMMON,
              list: []
            }
          ];
    this.getMoveFolders();
  }

  onHideModal() {
    this.$emit("hide");
  }

  getFileIcon(type: string) {
    return UploadFileType.getIconClassName(type);
  }

  openEditName(index: number) {
    this.selectIndex = index;
    this.isEdit = true;
    const uploadFileRef = `uploadFile${index}`;
    setTimeout(() => {
      const ref = (this.$refs[uploadFileRef] as any)[0];
      ref.focus();
      ref.select();
    }, 1);
  }

  downloadFile(doc: UploadFile) {
    this.$blockui.show();
    const fileName = doc.fileName;
    FileService.dowloadFile(fileName)
      .then(res => {
        if (res.status === 200) {
          const link = res.data;
          let a = document.createElement("a");
          a.href = link;
          if (doc.type === UploadFileType.PDF) {
            a.setAttribute("target", "_blank");
          } else {
            a.setAttribute("download", fileName);
          }
          a.click();
        }
      })
      .finally(() => this.$blockui.hide());
  }

  renameFile(index: number) {
    if (this.disableEdit) return;
    this.isEdit = false;
    const cDoc = this.tabs[this.tabIndex].list[index];
    if (cDoc.displayNameNew.trim() === cDoc.displayName) return;
    FileService.renameFile(cDoc.id, cDoc.displayNameNew.trim())
      .then(res => {
        if (res.status === 200) {
          cDoc.displayName = cDoc.displayNameNew;
          this.$swal.fire({
            icon: "success",
            title: "Đổi tên thành công!",
            position: "bottom-end",
            showConfirmButton: false,
            toast: true,
            timer: 2000
          });
        }
      })
      .catch(() => {
        this.$swal.fire({
          icon: "error",
          title: "Đổi tên thất bại!",
          position: "bottom-end",
          showConfirmButton: false,
          toast: true,
          timer: 2000
        });
      });
  }

  deleteFile(fileId: number) {
    if (this.disableEdit) return;
    this.$swal
      .fire({
        icon: "question",
        title: "Bạn thực sự muốn xóa tài liệu này?",
        confirmButtonColor: "danger",
        cancelButtonText: "Hủy",
        showCancelButton: true
      })
      .then(res => {
        if (res.isConfirmed) {
          FileService.deleteFile(fileId)
            .then(() => {
              this.tabs[this.tabIndex].list = this.tabs[
                this.tabIndex
              ].list.filter(item => item.id !== fileId);
              this.$swal.fire({
                icon: "success",
                title: "Xóa thành công!",
                position: "bottom-end",
                showConfirmButton: false,
                toast: true,
                timer: 2000
              });
            })
            .catch(() => {
              this.$swal.fire({
                icon: "error",
                title: "Xóa thật bại!",
                timer: 2000
              });
            });
        }
      });
  }

  moveFile(fileId: number, currentType: FileFolder, type: FileFolder) {
    const callAPI =
      this.billId !== -1 &&
      currentType === FileFolder.COMMON &&
      type !== FileFolder.COMMON
        ? FileService.moveFileWithBillId(fileId, this.billId, type)
        : FileService.moveFileWithoutBillId(fileId, type);

    callAPI
      .then(() => {
        this.getListDocument();
        this.$swal.fire({
          icon: "success",
          title: "chuyển thành công!",
          position: "bottom-end",
          showConfirmButton: false,
          toast: true,
          timer: 2000
        });
      })
      .catch(() => {
        this.$swal.fire({
          icon: "error",
          title: "chuyển thật bại!",
          timer: 2000
        });
      });
  }

  uploadFile(event: any) {
    if (
      this.blockAdd ||
      this.userRole === UserRole.GUEST ||
      this.userRole === UserRole.ACCOUNTANT
    )
      return;
    const allFile: File[] = event.target.files || event.dataTransfer.files;
    if (!allFile.length) return;
    const _50MB = 50 * 1048576;
    const files = [...allFile];
    if (files.some(file => file.size > _50MB)) {
      this.$swal.fire({
        icon: "warning",
        title:
          files.length === 1
            ? "File không thể lớn quá 50MB"
            : "Mỗi file không thể lớn quá 50MB",
        timer: 3000
      });
      return;
    }
    const formData = new FormData();
    formData.set("contractId", String(this.contractId));
    this.billId === -1 || !this.billId
      ? ""
      : formData.set("billId", String(this.billId));
    formData.set("folder", String(this.tabs[this.tabIndex].type));
    files.forEach(file => {
      formData.append("files", file);
    });
    this.blockAdd = true;
    FileService.uploadFile(formData)
      .then(() => {
        this.getListDocument();
        this.$swal.fire({
          icon: "success",
          title: "Tải lên thành công!",
          position: "bottom-end",
          showConfirmButton: false,
          toast: true,
          timer: 2000
        });
      })
      .catch(() => {
        this.$swal.fire({
          icon: "error",
          title: "Tải lên thất bại!",
          position: "bottom-end",
          showConfirmButton: false,
          toast: true,
          timer: 2000
        });
      })
      .finally(() => {
        this.blockAdd = false;
      });
  }

  closeModal() {
    this.$bvModal.hide(this.id);
  }
}

interface ListTabDocument {
  type: FileFolder;
  title: string;
  list: UploadFile[];
}
