import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import { ContractStatus, NewContract, PNote } from '@/models'

class ContractService {

  getContractsNew() {
    return axiosApiInstance.post(Const.api_url + '/contract/new')
  }

  getContractsNoBill() {
    return axiosApiInstance.post(Const.api_url + '/contract/ready')
  }

  setContractReady(contractId: number) {
    return axiosApiInstance.post(Const.api_url + `/contract/${contractId}/ready`)
  }

  undoContractsNoBill(contractId: number) {
    return axiosApiInstance.post(Const.api_url + `/contract/${contractId}/undo`)
  }

  getContractById(contractId: string | number) {
    return axiosApiInstance.get(Const.api_url + `/contract/${contractId}`)
  }

  createContract(newContract: NewContract) {
    return axiosApiInstance.put(Const.api_url + '/contract/save-detail', newContract)
  }

  createContractBasic(newContract: NewContract) {
    return axiosApiInstance.put(Const.api_url + '/contract/save-basic', newContract)
  }

  setProcessedContract(contractId: number) {
    return axiosApiInstance.post(Const.api_url + `/contract/${contractId}/process`)
  }

  deleteByContractId(contractId: number) {
    return axiosApiInstance.put(Const.api_url + `/contract/${contractId}/delete`)
  }

  updateContractNotice(contractId: number, notice: string) {
    return axiosApiInstance.post(Const.api_url + `/contract/${contractId}/notice`, { notice: notice })
  }

  findByContractCode(contractCode: string) {
    return axiosApiInstance.post(Const.api_url + `/contract/find`, contractCode)
  }

  export(contractId: string) {
    return axiosApiInstance.get(Const.api_url + `/export/${contractId}`)
  }

  getPNote(contractId: number) {
    return axiosApiInstance.get(Const.api_url + `/pnote/${contractId}`)
  }

  savePNote(pNote: PNote) {
    return axiosApiInstance.put(Const.api_url + `/pnote`, pNote)
  }
}

export default new ContractService
