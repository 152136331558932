
















































































































































































































































import { CommonData, ModalAddType } from "@/models";
import CommonService from "@/services/CommonService";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ModalAddCommon extends Vue {
  @Prop({ default: "modal-add-common" }) private id!: string;
  @Prop() modalType!: ModalAddType;
  @Prop() commonData!: CommonData;
  @Prop() private listCode!: string[];
  @Prop() private listName?: string[];
  private data: CommonData = new CommonData();
  private showCode: boolean = true;
  private showNotice: boolean = true;
  private showAll: boolean = true;
  private nameString: string = "Tên";
  private isEdit: boolean = false;
  private codeMax: number = 5;

  handleModalShow() {
    if (this.commonData && this.commonData.id) {
      this.data = this.$lodash.cloneDeep(this.commonData);
      this.isEdit = true;
    }
    if (this.modalType === ModalAddType.PRODUCT) {
      this.showCode = false;
      this.showAll = false;
      this.showNotice = false;
    } else if (this.modalType === ModalAddType.COUNTRY) {
      this.showCode = true;
      this.showNotice = false;
      this.showAll = false;
    } else if (this.modalType === ModalAddType.SUPPLIER) {
      this.showCode = true;
      this.showAll = true;
      this.showNotice = true;
      this.codeMax = 10;
    } else {
      this.showCode = true;
      this.showNotice = true;
      this.showAll = false;
    }
    this.nameString = this.handleNameString();
  }

  setFocus() {
    this.$refs.code
      ? (this.$refs.code as any)?.focus()
      : (this.$refs.name as any)?.focus();
  }

  handleModalHidden() {
    this.data = new CommonData();
    this.isEdit = false;
  }

  handleNameString() {
    switch (this.modalType) {
      case ModalAddType.CARRIER:
        return "Tên hãng tàu";
      case ModalAddType.COMPANY:
        return "Tên công ty";
      case ModalAddType.COUNTRY:
        return "Nơi xuất xứ";
      case ModalAddType.PORT:
        return "Tên cảng";
      case ModalAddType.PRODUCT:
        return "Tên mặt hàng";
      case ModalAddType.SUPPLIER:
        return "Tên nhà cung cấp";
      default:
        return "Tên";
    }
  }

  submitAdd() {
    const addSuccess = (busName: string) => {
      this.$swal({
        title: this.isEdit ? "Cập nhật thành công" : "Thêm thành công",
        icon: "success",
        timer: 2000,
        toast: true,
        showConfirmButton: false,
        position: "bottom-end"
      });
      this.$bus.$emit(busName);
      this.$emit("submit");
      this.$bvModal.hide(this.id);
    };

    const addFailed = () => {
      this.$swal({
        title: this.isEdit ? "Cập nhật thất bại" : "Thêm thất bại",
        icon: "error",
        timer: 2000,
        toast: true,
        showConfirmButton: false,
        position: "bottom-end"
      });
      this.$bvModal.hide(this.id);
    };

    switch (this.modalType) {
      case ModalAddType.CARRIER:
        CommonService.addCarrier(this.data)
          .then(res => {
            if (res.status === 200) addSuccess("addCarrier");
          })
          .catch(() => addFailed());
        break;
      case ModalAddType.COMPANY:
        CommonService.addCompany(this.data)
          .then(res => {
            if (res.status === 200) addSuccess("addCompany");
          })
          .catch(() => addFailed());
        break;
      case ModalAddType.COUNTRY:
        CommonService.addCountry(this.data)
          .then(res => {
            if (res.status === 200) addSuccess("addCountry");
          })
          .catch(() => addFailed());
        break;
      case ModalAddType.PORT:
        CommonService.addPort(this.data)
          .then(res => {
            if (res.status === 200) addSuccess("addPort");
          })
          .catch(() => addFailed());
        break;
      case ModalAddType.PRODUCT:
        CommonService.addProuct(this.data)
          .then(res => {
            if (res.status === 200) addSuccess("addProuct");
          })
          .catch(() => addFailed());
        break;
      case ModalAddType.SUPPLIER:
        CommonService.addSupplier(this.data)
          .then(res => {
            if (res.status === 200) addSuccess("addSupplier");
          })
          .catch(() => addFailed());
        break;
    }
  }
}
