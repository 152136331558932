export enum JobStatus {
  DOING,
  CONFIRM,
  DONE,
  DELETED
}

export class JobTask {
  id!: number
  creator: string = ''
  asignee: string = ''
  dateAsigned: string = ''
  job: string = ''
  status: JobStatus = JobStatus.DOING

  constructor(init?: Partial<JobTask>) {
    Object.assign(this, init);
  }
}

export enum TaskType {
  ASIGNEE,
  ASIGNER,
  OTHER
}