export default {
  lang: {
    vi: "Vietnamese",
    en: "English",
    other: "Other",
    choose_your_language: "Please choose your language",
  },
  route_title: {
    home: 'Home',
    todo: 'Todo'
  },
  common: {
    date: {
      next_month: "next month",
      prev_month: "last month",
      set_time: "time",
      mon: "mon",
      tue: "tue",
      wed: "wed",
      thu: "thu",
      fri: "fri",
      sat: "sat",
      sun: "sun",
      day: "day",
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December"
    }
  }
}