import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import store from '@/store'

import i18n from '@/lang'
import { UserRole } from './models'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/index.vue'),
    meta: {
      title: i18n.t('route_title.login'),
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/Dashboard/index.vue'),
    meta: {
      layout: LayoutDefault,
      title: i18n.t('route_title.dashboard'),
      requiresAuth: true
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/Calendar/index.vue'),
    meta: {
      layout: LayoutDefault,
      title: 'Lịch',
      requiresAuth: true
    }
  },
  {
    path: '/contracts',
    name: 'contract-list',
    component: () => import('@/views/Contract/ListContract/index.vue'),
    meta: {
      layout: LayoutDefault,
      title: i18n.t('route_title.contract'),
      requiresAuth: true
    }
  },
  {
    path: '/contract/complete/:contractId',
    name: 'contract-complete',
    component: () => import('@/views/Contract/CompleteContract/index.vue'),
    meta: {
      layout: LayoutDefault,
      title: i18n.t('route_title.contract'),
      requiresAuth: true
    }
  },
  {
    path: '/contract/:contractId',
    name: 'contract-detail',
    component: () => import('@/views/Contract/Detail/index.vue'),
    meta: {
      layout: LayoutDefault,
      title: i18n.t('route_title.contract'),
      requiresAuth: true
    }
  },
  {
    path: '/contract-temp/:contractId',
    name: 'contract-detail-temp',
    component: () => import('@/views/Contract/DetailTemp/index.vue'),
    meta: {
      layout: LayoutDefault,
      title: i18n.t('route_title.contract'),
      requiresAuth: true
    }
  },
  {
    path: '/upload-folder',
    name: 'upload-folder',
    component: () => import('@/views/UploadFolder/index.vue'),
    meta: {
      layout: LayoutDefault,
      title: 'Folder tải lên',
      requiresAuth: true
    }
  },
  {
    path: '/config',
    name: 'config',
    component: () => import('@/views/Config/index.vue'),
    meta: {
      layout: LayoutDefault,
      title: 'Tùy chỉnh',
      requiresAuth: true,
      adminAuth: true
    }
  },
  // {
  //   path: '/job',
  //   name: 'job',
  //   component: () => import('@/views/Job/index.vue'),
  //   meta: {
  //     layout: LayoutDefault,
  //     title: 'Giao việc',
  //     requiresAuth: true
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to: any, from: any, savedPosition: any) {
    // savedPosition is only available for popstate navigations.
    if (savedPosition) return savedPosition

    //prevent scroll when router change in same page
    if (to.name === from.name) return {}

    // scroll to anchor by returning the selector
    if (to.hash) {
      let position = { selector: to.hash }

      // specify offset of the element
      // if (to.hash === '#anchor2') {
      //   position.offset = { y: 100 }
      // }
      return position
    }

    // scroll to top by default
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = 'LUCAS | ' + to.meta.title ? to.meta.title : 'lucas'
  let token = store.state.auth.token
  let role = store.state.user.role
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else if ((to.name === 'contract-list' || to.name === 'contract-complete') && (role === UserRole.ACCOUNTANT)) {
    next({ path: '/' })
  } else {
    next()
  }
})

export default router
