import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import { FileFilter, FileFolder } from '@/models'

class FileService {

  getListFiles(fileFilter: FileFilter) {
    return axiosApiInstance.post(Const.api_url + '/file', fileFilter)
  }

  getFileByBillId(billId: number) {
    return axiosApiInstance.post(Const.api_url + `/file/${billId}`)
  }

  getFileByContractId(contractId: number) {
    return axiosApiInstance.post(Const.api_url + `/file/contract/${contractId}`)
  }

  uploadFile(formData: any) {
    return axiosApiInstance.post(Const.api_url + `/file/upload`, formData)
  }

  dowloadFile(fileName: string) {
    return axiosApiInstance.post(Const.api_url + `/file/download`, fileName)
  }

  renameFile(fileId: number, newName: string) {
    return axiosApiInstance.put(Const.api_url + `/file/rename/${fileId}`, newName)
  }

  deleteFile(fileId: number) {
    return axiosApiInstance.delete(Const.api_url + `/file/delete/${fileId}`)
  }

  moveFileWithBillId(fileId: number, billId: number, folder: FileFolder) {
    return axiosApiInstance.put(Const.api_url + `/file/${fileId}/move/${billId}/${folder}`)
  }

  moveFileWithoutBillId(fileId: number, folder: FileFolder) {
    return axiosApiInstance.put(Const.api_url + `/file/${fileId}/move/${folder}`)
  }
}

export default new FileService
