














































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Container, Product, UserRole } from "@/models";

@Component({ components: { ContainerDetail } })
export default class ContainerDetail extends Vue {
  @Prop() private container!: Container;
  @Prop() private products!: Product[];
  @Prop() private productOnly?: boolean;
  private listLoop: Product[] = [];

  //role
  private userRole: UserRole = this.$store.state.user.role;
  private eUserRole = UserRole;

  created() {
    this.setListLoop();
  }

  @Watch("container")
  @Watch("products")
  setListLoop() {
    this.listLoop = this.productOnly
      ? this.products
      : this.container.containerProducts;
  }

  formatDate(date: any) {
    return this.$moment(date).format("DD-MM-YYYY");
  }

  formatCurrency(num: number) {
    return new Intl.NumberFormat("currency").format(num);
  }
}
