
















































































































































































































































import { Contract, ContractCSV, ContractStatus, UserRole } from "@/models";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BillDetail from "@/views/Contract/Detail/BillDetail.vue";
import ContractService from "@/services/ContractService";
import ContainerDetail from "@/views/Contract/Detail/ContainerDetail.vue";
import ModalDocument from "@/components/Modal/ModalDocument.vue";

@Component({ components: { BillDetail, ContainerDetail, ModalDocument } })
export default class ContractDetail extends Vue {
  @Prop() private ctrId!: string;
  @Prop() private bId?: string;
  private contractId: string = this.ctrId;
  private billId: string = "";
  private contract: Contract = new Contract();
  private eContractStatus = ContractStatus;

  //role
  private userRole: UserRole = this.$store.state.user.role;
  private eUserRole = UserRole;

  //csv
  private csvData: any = [];
  private csvHeader: any = {};
  private csvFields: any = {};

  created() {
    this.setContractId();
    this.setBillId();
    this.getContractDetail();
  }

  scrollToBill() {
    if (this.billId) {
      (this.$refs[`billDetail${this.billId}`] as any[])[0].$el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    }
  }

  @Watch("$route.params.contractId")
  setContractId() {
    if (this.$route.params.contractId) {
      this.contractId = this.$route.params.contractId;
    }
  }

  @Watch("$route.query.billId")
  setBillId() {
    if (this.$route.query.billId) {
      this.billId = this.$route.query.billId as string;
    } else {
      this.billId = this.bId ? this.bId : "";
    }
  }

  /**
   * Call API to get contract detail
   */
  @Watch("contractId")
  getContractDetail() {
    const formatCurrency = (str: string) =>
      new Intl.NumberFormat("currency").format(Number(str));

    ContractService.getContractById(this.contractId).then(res => {
      if (res.status === 200) {
        this.contract = res.data;
        this.contract.totalValue = formatCurrency(this.contract.totalValue);
        // this.contract.totalPrice = formatCurrency(this.contract.totalPrice);
        this.contract.totalValueTemp = formatCurrency(
          this.contract.totalValueTemp
        );
        // this.contract.totalPriceTemp = formatCurrency(
        //   this.contract.totalPriceTemp
        // );
        setTimeout(() => {
          this.scrollToBill();
        }, 10);

        this.setCsvHeader();
      }
    });
  }

  formatDate(date: any) {
    return date
      ? this.$moment(date, "DD-MM-YYYY HH:mm:ss")
          .format("DD MMM YY")
          .toUpperCase()
      : "";
  }

  openModalDocument() {
    this.$bvModal.show("modal-document-contract-detail");
  }

  jumpToEdit() {
    if (
      this.userRole === UserRole.GUEST ||
      this.userRole === UserRole.ACCOUNTANT
    )
      return;
    const routeData = this.$router.resolve({
      name: "contract-complete",
      params: {
        contractId: this.contractId,
        from: this.$route.path
      }
    });
    window.open(routeData.href, '_blank')
  }

  exportCSV() {
    if (
      this.userRole === UserRole.GUEST ||
      this.userRole === UserRole.ACCOUNTANT
    )
      return;
    if (this.contract.status === ContractStatus.NEW) {
      const toString = (str: string) => {
        const mm = this.$moment(str, "DD-MM-YYYY HH:mm:ss");
        if (mm.isValid()) {
          return mm.format("DD MMM YY").toUpperCase();
        }
        return "";
      };

      this.csvData = this.contract.contractProductList.map(
        prod =>
          new ContractCSV({
            company: this.contract.company,
            supplier: this.contract.supplier,
            contractCode: String(this.contract.code),
            brand: this.contract.brand,
            timeSigned: toString(String(this.contract.timeSigned)),
            license: this.contract.license,
            paymentMethod: this.contract.paymentMethod,
            exchangeRate: this.contract.exchangeRate,
            origin: this.contract.origin,
            productName: prod.productName,
            value: String(prod.value ? prod.value : 0),
            // price: String(prod.price ? prod.price : 0),
            weight: String(prod.weight ? prod.weight : 0),
            packs: String(prod.packs ? prod.packs : 0)
          })
      );
      setTimeout(() => (this.$refs.downloadCsv as any).$el.click(), 10);
    } else {
      ContractService.export(this.contractId).then(res => {
        if (res.status === 200) {
          this.csvData = res.data.map(
            (item: ContractCSV) => new ContractCSV(item)
          );
          setTimeout(() => (this.$refs.downloadCsv as any).$el.click(), 10);
        }
      });
    }
  }

  setCsvHeader() {
    if (this.contract.status === ContractStatus.NEW) {
      this.csvFields = [
        "company",
        "supplier",
        "contractCode",
        "brand",
        "timeSigned",
        "license",
        "paymentMethod",
        "exchangeRate",
        "origin",
        "productName",
        "value",
        // "price",
        "weight",
        "packs"
      ];
      this.csvHeader = {
        company: "Công ty",
        supplier: "Nhà cung cấp",
        contractCode: "Mã hợp đồng",
        brand: "Thương hiệu",
        timeSigned: "Ngày ký",
        license: "Giấy phép",
        paymentMethod: "Phương thức thanh toán",
        exchangeRate: "Tỷ giá",
        origin: "Xuất xứ",
        productName: "Tên mặt hàng",
        value: "Đơn giá hải quan ($/kg)",
        // price: "Đơn giá thực tế ($/kg)",
        weight: "Khối lượng (kg)",
        packs: "Số kiện"
      };
    } else {
      this.csvFields = [
        "company",
        "supplier",
        "contractCode",
        "brand",
        "timeSigned",
        "license",
        "paymentMethod",
        "exchangeRate",
        "origin",
        "billNo",
        "invoiceNo",
        "shipCode",
        "carrier",
        "billDate",
        "dpName",
        "apName",
        "fpName",
        "etd",
        "eta",
        "etf",
        "vetDate",
        "clearDate",
        "containerCode",
        "productName",
        "value",
        // "price",
        "weight",
        "packs"
      ];
      this.csvHeader = {
        company: "Công ty",
        supplier: "Nhà cung cấp",
        contractCode: "Mã hợp đồng",
        brand: "Thương hiệu",
        timeSigned: "Ngày ký",
        license: "Giấy phép",
        paymentMethod: "Phương thức thanh toán",
        exchangeRate: "Tỷ giá",
        origin: "Xuất xứ",
        billNo: "Số bill",
        invoiceNo: "Số invoice",
        shipCode: "Mã tàu",
        carrier: "Hãng tàu",
        billDate: "Ngày INV",
        dpName: "DP",
        apName: "TP",
        fpName: "FP",
        etd: "ETD",
        eta: "ETP",
        etf: "ETA",
        vetDate: "Ngày kiểm kịch",
        clearDate: "Ngày thông quan",
        containerCode: "Mã container",
        productName: "Tên mặt hàng",
        value: "Tổng giá hải quan ($/kg)",
        // price: "Tổng giá thực tế ($/kg)",
        weight: "Khối lượng (kg)",
        packs: "Số kiện"
      };
    }
  }
}
