


































































































































import UserService from "@/services/UserService";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ModalChangePassword extends Vue {
  @Prop({ default: "modal-change-password" }) private id!: string;
  @Prop() private isAdmin!: boolean;
  @Prop() private username!: string;
  private currentPass: string = "";
  private newPass: string = "";
  private confirmPass: string = "";
  private md5 = require("md5");

  changePassword() {
    if (this.isAdmin) {
      this.changePassAdmin();
    } else {
      this.changePass();
    }
  }

  changePass() {
    UserService.changePassword(
      this.username,
      this.md5(this.currentPass.trim()),
      this.md5(this.newPass.trim())
    )
      .then(res => {
        if (res.status === 200) {
          this.$swal({
            title: "Đổi mật khẩu thành công",
            icon: "success",
            timer: 2000,
            toast: true,
            showConfirmButton: false,
            position: "bottom-end"
          });
          this.$bvModal.hide(this.id);
        }
      })
      .catch(() => {
        this.$swal({
          title: "Đổi mật khẩu thất bại",
          icon: "error",
          timer: 2000,
          toast: true,
          showConfirmButton: false,
          position: "bottom-end"
        });
      });
  }

  changePassAdmin() {
    UserService.changePasswordAdmin(
      this.username,
      this.md5(this.newPass.trim())
    )
      .then(res => {
        if (res.status === 200) {
          this.$swal({
            title: "Đổi mật khẩu thành công",
            icon: "success",
            timer: 2000,
            toast: true,
            showConfirmButton: false,
            position: "bottom-end"
          });
          this.$bvModal.hide(this.id);
        }
      })
      .catch(() => {
        this.$swal({
          title: "Đổi mật khẩu thất bại",
          icon: "error",
          timer: 2000,
          toast: true,
          showConfirmButton: false,
          position: "bottom-end"
        });
      });
  }

  reset() {
    this.newPass = "";
    this.currentPass = "";
    this.confirmPass = "";
  }
}
