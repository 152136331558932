







































































































































































import { Bill, Container, UserRole } from "@/models";
import { Component, Prop, Vue } from "vue-property-decorator";
import ContainerDetail from "@/views/Contract/Detail/ContainerDetail.vue";

@Component({ components: { ContainerDetail } })
export default class BillDetail extends Vue {
  @Prop({ default: new Bill() }) private bill!: Bill;

  //role
  private userRole: UserRole = this.$store.state.user.role;
  private eUserRole = UserRole;

  formatDate(date: any) {
    const moment = this.$moment(date, "DD-MM-YYYY HH:mm:ss");
    return moment.isValid() ? moment.format("DD MMM YY").toUpperCase() : "";
  }

  formatTotalValue(container: Container) {
    let totalValue = 0;
    container.containerProducts.forEach(prod => {
      totalValue = totalValue + prod.value * prod.weight;
    });
    return new Intl.NumberFormat("currency").format(totalValue);
  }

  // formatTotalPrice(container: Container) {
  //   let totalPrice = 0;
  //   container.containerProducts.forEach(prod => {
  //     totalPrice = totalPrice + prod.price * prod.weight;
  //   });
  //   return new Intl.NumberFormat("currency").format(totalPrice);
  // }
}
