import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '@/store'
import router from '@/router'
import AuthService from '@/services/AuthService';

const axiosInstance = axios.create();

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = store?.state?.auth?.token
    config.headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
    if (token) {
      config.headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }
    }
    return config;
  },
  error => {
    Promise.reject(error)
  }
)

// Response interceptor for API calls
axiosInstance.interceptors.response.use((response: AxiosResponse) => {
  return response
}, async (error) => {
  const originalRequest = error ? error.config : null;

  if (
    !error || !error.response || !error.response.status || error.response.status === 403 || error.response.status === 401
    // && !originalRequest._retry
    // && error?.response?.data?.status_code === "TOKEN_EXPIRED"
  ) {
    const token = store.state.auth.token;
    token ? AuthService.logout(token) : "";
    router.push({ name: 'login' })
    localStorage.clear()
  }

  // if (
  //   error.response.status === 401
  //   && (error?.response?.data?.status_code === "INVALID_USER" || error?.response?.data?.status_code === "INVALID_TOKEN")
  // ) {
  //   router.push({ name: 'login' })
  //   localStorage.clear();
  // }

  return Promise.reject(error);
});

export default axiosInstance;
