import moment from 'moment';
import { BillStatus } from '../Bill';

export class UploadFile {
  id!: number;
  displayName: string = "";
  displayNameNew: string = "";
  type: string = "";
  fileName: string = "";
  folder: FileFolder = FileFolder.COMMON

  constructor(init?: Partial<UploadFile>) {
    Object.assign(this, init);
    this.displayNameNew = this.displayName;
  }
}

export class UploadFolder {
  billId!: number;
  contractId!: number;
  billCode: string = '';
  contractCode: string = '';
  invoiceCode: string = '';
  fileList: any
  eta: string = '';

  //VM
  code: string = ''

  constructor(init?: Partial<UploadFolder>) {
    Object.assign(this, init);
    this.code = [this.contractCode, this.billCode, this.invoiceCode].filter(i => i).join("/")

    const toString = (str: string) => {
      const mm = moment(str, "DD-MM-YYYY HH:mm:ss");
      if (mm.isValid()) {
        return mm.format("DD MMM YY").toUpperCase();
      }
      return "";
    };

    this.eta = toString(this.eta);

  }
}

export class FileFilter {
  etaFrom: Date | string | null = null;
  etaTo: Date | string | null = null;
  clearFrom: Date | string | null = null;
  clearTo: Date | string | null = null;
  statuses: BillStatus[] = []

  //VM
  process: boolean = true;
  done: boolean = false;

  //fnc
  formatDate() {
    this.clearFrom = this.clearFrom ? moment(this.clearFrom).format("DD-MM-YYYY 00:00:00") : null
    this.clearTo = this.clearTo ? moment(this.clearTo).format("DD-MM-YYYY 3:59:99") : null
    this.etaFrom = this.etaFrom ? moment(this.etaFrom).format("DD-MM-YYYY 00:00:00") : null
    this.etaTo = this.etaTo ? moment(this.etaTo).format("DD-MM-YYYY 23:59:99") : null
  }

  constructor(init?: Partial<FileFilter>) {

    const today = moment().toDate()
    const firstDoM = moment().startOf('month').toDate()
    this.etaFrom = firstDoM
    // this.clearFrom = firstDoM
    this.etaTo = today
    // this.clearTo = today

    Object.assign(this, init);
    this.statuses = []
    this.process ? this.statuses.push(BillStatus.PROCESSING) : ''
    this.done ? this.statuses.push(BillStatus.DONE) : ''
  }
}

export enum FileFolder {
  COMMON,
  INV_PL,
  HC_CO_BL_INS,
  DECLARE,
  B15,
  BANK,
  GUEST
}
