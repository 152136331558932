export default {
  code: "vi",
  fields: {
    username: "tên đăng nhập",
    password: "mật khẩu",
    company: "công ty",
    contract_code: "mã HĐ",
    contract_total: "giá trị HĐ",
    regist_date: "ngày ký",
    exchange_rate: "tỷ giá",
    contract_value: "giá hợp đồng",
    contract_price: "giá thực tế",
    contract_weight: "khối lượng",
    notice: "ghi chú",
    brand: "thương hiệu",
    origin: "xuất xứ",
    supplier: "nhà cung cấp",
    payment: "phương thức thanh toán",
    product: "mặt hàng",
    bill_no: "số bill",
    bill_date: "ngày INV",
    invoice_no: "số invoice",
    carrier: "hãng tàu",
    ship_code: "mã tàu",
    dp: "DP",
    ap: "TP",
    fp: "FP",
    etd: "ETD",
    eta: "ETD",
    etf: "ETA",
    code: "mã",
    name: "tên",
    new_pass: "mật khẩu mới",
    confirm_pass: "mật khẩu xác nhận",
    old_pass: "mật khẩu cũ",
    task: "công việc",
    asignee: "người nhận việc"
  },
  messages: {
    confirm_pass: "mật khẩu không khớp",
    in_list: "{_field_} phải thuộc danh sách sẵn có",
    not_in_list: "{_field_} đã bị trùng",
    email: "Please enter your e-mail address correctly.",
    length: "The {_field_} field must be {length} long",
    max_value: "The {_field_} field must be {max} or less",
    min_value: "{_field_} phải chọn {min} hoặc hơn",
    min_date: "{_field_} must be at least {date_format}",
    max_date: "{_field_} must be less than or equal to {date_format}",
    min: "{_field_} phải có ít nhất {length} ký tự",
    max: "{_field_} phải không được vượt quá {length} ký tự",
    numeric: "{_field_} chỉ có thể nhập số",
    regex: "The {_field_} field format is invalid",
    required: "Hãy nhập {_field_}",
    size: "The {_field_} field size must be less than {size}KB",
    input: {
      required: "is required",
      exceedsPermissibleLength: "more than allowable length",
      formatError: "format error",
      lessThanPermissibleLength: "less than allowable length"
    },
    check_password: "Password is invalid",
    postal_code: "Zip code must be 7 digits excluding dashes",
    phone_number:
      "Phone numbers can contain up to 13 numbers, including dashes",
    url: "URL is not a valid URL",
    duplicate_orignal_email:
      "The email address you entered and the original email address cannot be the same",
    check_email:
      "The second email address and the third email address cannot be the same",
    check_kana: "Enter {_field_}as hiragana",
    text_number_only: "{_field_} should not be able to use emojis and symbols",
    text_number_only_no_space:
      "{_field_} should not be able to use emojis, symbols, and spaces",
    no_emoji: "{_field_} should not use emojis",
    compare_start_end_time: "Start time can not be the same as end time.",
    between: "The {_field_} field must be between {min} and {max}",
    invalid: "format error",
    business: {
      emailNotRegistered: "Email not registerd",
      userPasswordNotMatch: "User password not match",
      userNotFound: "User not found",
      sendMailSuccess:
        "We have sent an e-mail with the password reset URL to the specified e-mail address. please confirm.",
      invalidRegistrationTime: "Reservations cannot be made retroactively",
      seatIsAlreadyRegistered:
        "The seat has already been reserved for another user. Click the display button again to refresh the screen.",
      outsideWorkingHours: "It is outside business hours"
    },
    departmentVsGroupInvalid:
      "There is no link between department and section. please confirm.",
    phoneNumberGroup: "{_field_} is invalid.",
    minTime: "Closing time must be greater than opening time.",
    maxTime: "The Closing time must be less than 23:59",
    no_script_tag: "{_field_} chứa những ký tự không hợp lệ"
  }
};
