export enum UserRole {
  ACCOUNTANT = "[ACC]",
  GUEST = "[GUEST]",
  MODERATOR = "[MODERATOR]",
  ADMIN = "[ADMIN]"
}

export enum UserStatus {
  DEACTIVE,
  ACTIVE
}


export enum USER_ROLE {
  ADMIN,
  MODERATOR,
  GUEST,
  ACC
}

export interface IUserInfo {
  fullname: string
  username: string
  role: UserRole
}

export class UserInfo {
  id!: number
  name: string = ''
  role: USER_ROLE = USER_ROLE.ACC
  username: string = ''
  status: UserStatus = UserStatus.ACTIVE
  phone: string = ''
  notice: string = ''

  //view modal
  originalIndex: number = 0

  constructor(init?: Partial<UserInfo>) {
    Object.assign(this, init);
  }
}
