import { extend, configure } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import moment from 'moment'
import i18n from '@/lang'
//@ts-ignore
import _ from 'lodash'

// install rules and localization
for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation
  })
}

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    if (!field.includes('ignore')) {
      values._field_ = i18n.t(`fields.${field}`)
    }
    return i18n.t(`validation.${values._rule_}`, values) as string
  }
})

extend('max', {
  validate(value: any, event: any) {
    return new Blob([value]).size <= (event.length * 4) && _.toArray(value).length <= event.length
  }
})

extend('min_date', {
  params: ['min', 'date_format'],
  validate(value, event: any) {
    return moment(value).isAfter(moment(event.min))
  }
})

extend('max_date', {
  params: ['max', 'date_format'],
  validate(value, event: any) {
    return moment(value).isBefore(moment(event.max))
  }
})

extend('in_list', {
  params: ['list'],
  validate(value, event: any) {
    return event.list.toLowerCase()
      .indexOf(value.toLowerCase()) > -1
  }
})

extend('not_in_list', {
  params: ['list'],
  validate(value, event: any) {
    const listUpper = event.list?.map((item: string) => item.toLowerCase())
    return !listUpper.includes(value.trim().toLowerCase())
  }
})

extend('confirm_pass', {
  params: ['pass'],
  validate(value, event: any) {
    return event.pass === value
  }
})

export const emailRegex = /^((?=[^@]*[a-z])([a-z0-9])(([a-z0-9])*([._-])?([a-z0-9]))*@(([a-z0-9])+(\.))+([a-z]{2,4})).*$/
extend('email', {
  validate(value) {
    return emailRegex.test(value)
  },
})

extend('check_password', {
  validate(value) {
    const passwordRegex = /^((?=.*[A-Z])(?=.*[a-z])|(?=.*[A-Z])(?=.*[0-9])| (?=.*[A-Z])(?=.*[!@\#$%&\/=?_.,:;\\-])|(?=.*[a-z])(?=.*[0-9])|(?=.*[a-z])(?=.*[!@\#$%&\/=?_.,:;\\-])|(?=.*[0-9])(?=.*[!@\#$%&\/=?_.,:;\\-])).{8,}$/
    return passwordRegex.test(value) && (value.length === new Blob([value]).size)
  },
})

extend('check_kana', {
  validate(value) {
    const kanaRegex = /^[\u3040-\u309f]*$/
    return kanaRegex.test(value)
  }
})

extend('check_email', {
  params: ['target'],
  validate(value, target: any) {
    return value !== target.target
  },
})

extend('duplicate_orignal_email', {
  params: ['target'],
  validate(value, target: any) {
    return value !== target.target
  },
})

extend('postal_code', {
  validate(value) {
    const postalRegex = /^\d{3}\d{4}$/
    return postalRegex.test(value)
  }
})

extend('phone_number', {
  validate(value) {
    const verifyPhone = /^(([0-9]{1,3}-[0-9]{1,4}-[0-9]{1,4}$)|([0-9]{1,4}-[0-9]{1,4}-[0-9]{1,3}$)|([0-9]{1,4}-[0-9]{1,3}-[0-9]{1,4}$)|([0-9]{1,6}-[0-9]{1,6}$))/
    return verifyPhone.test(value)
  }
})

extend('url', {
  validate(value: string | null | undefined): boolean {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
    }

    return false;
  },
})

/**
 * @denied emoji
 * @denied keep enter
 * @denied special characters
 */
extend('text_number_only', {
  validate(value) {
    const textNumberRegex = /^[a-zA-Z0-9-ぁ-んァ-ン一ー-龥ｧ-ﾝﾞﾟ ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳýỵỷỹ]+$/
    return textNumberRegex.test(value)
  }
})

/**
 * @denied emoji
 * @denied space
 * @denied keep enter
 * @denied special characters
 */
extend('text_number_only_no_space', {
  validate(value) {
    const textNumberRegex = /^[a-zA-Z0-9-ぁ-んァ-ン一ー-龥ｧ-ﾝﾞﾟÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳýỵỷỹ]+$/
    return textNumberRegex.test(value)
  }
})

extend('compare_start_end_time', {
  params: ['target'],
  validate(value, target: any) {
    return value > target.target;
  },
})


export const checkScript = (value: string) => ((value.includes('<script') && value.includes('</script')) || value.includes('<?') || value.includes('<%'))
extend('no_script_tag', {
  validate(value) {
    return !checkScript(value)
  }
})
