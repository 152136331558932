var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-add-common"},[_c('b-modal',{attrs:{"id":_vm.id,"body-class":"px-7","header-class":"pb-0","centered":"","hide-footer":""},on:{"shown":_vm.reset,"hidden":_vm.reset}},[_c('ValidationObserver',{staticClass:"d-flex flex-column flex-center mb-5",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"form w--60 d-flex flex-column flex-center"},[(!_vm.isAdmin)?_c('div',{staticClass:"old-pass w-100 mb-2"},[_c('label',[_vm._v(" Mật khẩu hiện tại "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ValidationProvider',{attrs:{"rules":{
                required: true,
                min: 8
              },"name":"old_pass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentPass),expression:"currentPass"}],ref:"old_pass",staticClass:"form-control bg-light position-relative z-1 fs-11 fs-xl-14 h--8",class:{ 'border-danger': errors[0] },attrs:{"type":"password","name":"old_pass"},domProps:{"value":(_vm.currentPass)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currentPass=$event.target.value}}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1 fs-12"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"new-pass w-100 mb-2"},[_c('label',[_vm._v(" Mật khẩu mới "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ValidationProvider',{attrs:{"rules":{
                required: true,
                min: 8
              },"name":"new_pass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPass),expression:"newPass"}],ref:"new_pass",staticClass:"form-control bg-light position-relative z-1 fs-11 fs-xl-14 h--8",class:{ 'border-danger': errors[0] },attrs:{"type":"password","name":"new_pass"},domProps:{"value":(_vm.newPass)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newPass=$event.target.value}}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1 fs-12"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),(!_vm.isAdmin)?_c('div',{staticClass:"old-pass w-100 mb-2"},[_c('label',[_vm._v(" Xác nhận mật khẩu "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"form-input"},[_c('ValidationProvider',{attrs:{"rules":{
                required: true,
                min: 8,
                confirm_pass: { pass: _vm.newPass }
              },"name":"confirm_pass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPass),expression:"confirmPass"}],ref:"confirm_pass",staticClass:"form-control bg-light position-relative z-1 fs-11 fs-xl-14 h--8",class:{ 'border-danger': errors[0] },attrs:{"type":"password","name":"confirm_pass"},domProps:{"value":(_vm.confirmPass)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPass=$event.target.value}}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1 fs-12"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]):_vm._e()]),_c('div',{staticClass:"actions-area d-flex justify-content-end w-100 pt-5"},[_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn btn-primary fs-11 fs-xl-14 w-100",on:{"click":function($event){return handleSubmit(_vm.changePassword)}}},[_vm._v(" Cập nhật ")])])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }