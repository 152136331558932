class PreviewText {

  covertToPreviewText(string: string, max: number = 30) {
    const origin = string
    let text = string
    let isLong = false
    if (text?.length > max) {
      isLong = true
      text = `${text.slice(0, max)}...`
    }
    return {
      isLong: isLong,
      text: text,
      origin: origin
    }
  }
}

export interface IPreviewText {
  isLong: boolean,
  text: string,
  origin: string
}

export default new PreviewText()
