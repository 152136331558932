



























import { UserRole } from "@/models";
import { Component, Prop, Vue } from "vue-property-decorator";
import { SidebarMenu } from "vue-sidebar-menu";

@Component({ components: { SidebarMenu } })
export default class Sidebar extends Vue {
  @Prop() private sideBarCollapse!: boolean;
  private menu: any[] = [
    {
      header: true,
      title: "",
      class: "h--13"
      // hiddenOnCollapse: true
    },
    {
      href: "/",
      title: "Theo dõi",
      icon: "fas fa-tachometer-alt"
    },
    {
      href: "/calendar",
      title: "Lịch",
      icon: "fas fa-calendar-alt"
    },
    {
      href: "/contracts",
      title: this.$t("sidebar_title.contract"),
      icon: "fas fa-file-contract",
      hiddenOnCollapse: this.$store.state.user.role === UserRole.ACCOUNTANT
    },
    {
      href: "/upload-folder",
      title: "Tài liệu",
      icon: "fas fa-folder"
    },
    // {
    //   href: "/job",
    //   title: "Giao việc",
    //   icon: "fas fa-bolt"
    // },
    {
      href: "/config",
      title: this.$t("sidebar_title.custom"),
      icon: "fas fa-cogs"
    }
  ];

  onToggleCollapse(collapsed: boolean) {
    this.$emit("toggleCollapse", collapsed);
  }
}
