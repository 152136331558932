export class IdName {
  id!: number;
  name: string = "";
  constructor(init?: Partial<IdName>) {
    Object.assign(this, init);
  }
}

export class IdNameCode {
  id!: number;
  name: string = "";
  code: string = "";
  constructor(init?: Partial<IdNameCode>) {
    Object.assign(this, init);
  }
}

export class CommonData {
  id!: number;
  name: string = "";
  code: string = "";
  color: string = "";
  notice: string = "";
  phone: string = "";
  email: string = "";
  address: string = "";
  bank: string = "";
  bankAddress: string = "";
  bankAccount: string = "";
  iban: string = "";

  constructor(init?: Partial<CommonData>) {
    Object.assign(this, init);
  }
}

export enum ModalAddType {
  SUPPLIER,
  COMPANY,
  PORT,
  COUNTRY,
  CARRIER,
  PRODUCT
}

export class SearchResult {
  contractId: string = "";
  contractCode: string = "";
  billNo: string = "";
  invoiceNo: string = "";
  eta: string = "";
  bstatus: Number = -1;
  cstatus: Number =-1;
}
