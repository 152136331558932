import { IUserInfo, UserRole } from '@/models/User'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'user' })
class User extends VuexModule {
  username: string = ''
  fullname: string = ''
  role: UserRole = UserRole.ACCOUNTANT

  @Mutation
  setNewUserUnfo(user: IUserInfo) {
    this.username = user.username
    this.fullname = user.fullname
    this.role = user.role
  }

  @Action
  SET_NEW_USER_INFO(user: IUserInfo) {
    this.context.commit('setNewUserUnfo', user)
  }
}

export default User